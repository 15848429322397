.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.loading .ant-spin {
  color: #000;
}

.custom-button {
  line-height: 1.5;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 8.5px 1.25rem;
  font-size: 14px;
  border-radius: 0.625rem;
  color: #455560;
  background: #fff;
  border-color: #e6ebf1;
}

.ant-picker-range-wrapper.ant-picker-date-range-wrapper {
  justify-content: center;
}

.ant-modal-footer {
  border: none;
}

.ant-table-tbody > tr > td {
  padding: 0.5px;
}

.fbo-active-box {
  border: 1px solid #536dfe;
  box-shadow: 1px 1px 5px #d3d3d3;
}

.negative-target {
  color: red;
}

.flex-justify {
  display: flex;
  justify-content: space-around;
}

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

.ant-table-cell {
  padding: 10px 5px !important;
}
